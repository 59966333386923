const ADD 		= (state) => {
	state.requestLoading++
}

const REM 		= (state) => {
	state.requestLoading--
}

const UPDATE_ALL_PLANS = (state, payload) => {
	state.all_plans = payload
}

const UPDATE_SELECTED_PLAN = (state, payload) => {
	state.selected_plan = payload
}

const UPDATE_KEEP_CONNECTED = (state, payload) => {
	state.keep_connected = payload
}

export {
	ADD,
	REM,
	UPDATE_KEEP_CONNECTED,
	UPDATE_ALL_PLANS,
	UPDATE_SELECTED_PLAN
}