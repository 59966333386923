import Vuex from './vuex'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'

NProgress.configure({
    showSpinner: false
});

const router = new VueRouter({
    mode:   'history',
    routes: [
        {
			path: '/',
			component: require('./components/layout/Index.vue').default,
			children: [
				{
					name: 'Login',
					path: '/login',
					props: true,
					component: require('./components/login/Index.vue').default,
					meta: {
						title: 'Login'
					}
				},
				{
					name: 'RecoveryPasswordWithTokenIndex',
					path: '/senha/token/:token',
					props: true,
					component: require('./components/recovery_passwd/Index.vue').default,
					meta: {
						title: 'Recuperar Senha'
					}
				},
                {
                    path: '/cadastro',
                    component: require('./components/layout/EmptyParentComponent.vue').default,
                    children: [
                        {
                            name: 'Register',
                            path: '',
                            props: true,
                            component: require('./components/register/RegisterTab.vue').default,
                            meta: {
                                title: 'Cadastro'
                            }
                        },
                        {
                            name: 'RegisterWithTokenIndex',
                            path: 'token/:token',
                            props: true,
                            component: require('./components/register/Index.vue').default,
                            meta: {
                                title: 'Registro'
                            }
                        },
                        {
                            path: '',
                            redirect: {
                                name: 'Home'
                            }
                        }
                    ]
                },
                {
                    name: 'EmailValidation',
                    path: '/validacao/token/:token',
                    component: require('./components/validation/EmailValidation.vue').default,
                    props: true,
                    meta: {
                        title:          'Validação de e-mail',
                    }
                },
                {
                    path: '',
                    redirect: {
                        name: 'Login'
                    }
                }
			]
		},
        {
            path: '*',
            redirect: {
				name: 'Login'
            }
        },
    ]
});

router.beforeEach((to, from, next) => {

    NProgress.start()

    next()
})

router.afterEach((to) => {
    document.title = 'IM | ' + to.meta.title

    NProgress.done()
})

export default router;