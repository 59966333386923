const requests 	= (state) => {
	return state.requestLoading
}

const menu_options = (state) => {
	return state.menu_options
}

const all_plans = (state) => {
	return state.all_plans
}

const selected_plan = (state) => {
	return state.selected_plan
}

const keep_connected = (state) => {
	return state.keep_connected
}

export {
	requests,
	menu_options,
	keep_connected,
	all_plans,
	selected_plan
}