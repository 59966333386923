import router from '../../routes'

const api_url 	= process.env.VUE_APP_API_URL

const OAuth 	= ({ commit }, payload) => {
	return new Promise((resolve, reject) => {
		window.axios.post(`${api_url}/api/website/oauth`, payload)
		.then(({data}) => {

			if(data.response)
			{
				resolve(data)

			}else{

				reject(data)
			}

		}).catch(({response}) => {

			reject(response)

		})
	})
}

const Login = async ({ commit, dispatch }, payload) => {
	await dispatch('system/KeepConnectedHandler', payload.keep_connected, { root: true })

	return new Promise((resolve, reject) => {
		const encodedCredentials = btoa(`${payload.username}:${payload.password}`)

		window.axios.post(`${api_url}/api/website/login`, {
			keep_connected: payload.keep_connected,
		}, {
			headers: {
				'Authorization': `Basic ${encodedCredentials}`
			}
		})
		.then(({data}) => {
			if(data.response) {
				resolve(data)
			} else {
				reject(data)
			}
		})
		.catch(({response}) => {

			reject(response)
		})
	})
}


export {
	Login,
	OAuth
}