<template>
    <Entry
		ref="entry"
		type="register"
		:height="700"
	/>
</template>

<script>
import Entry from '../home/sections/Entry'
export default {
    components: {
        Entry
    }
}
</script>

<style>

</style>