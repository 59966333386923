<template>
	<Entry
		ref="entry"
		type="login"
		:height="700"
		:bridge_key="bridge_key"
	/>
</template>

<script>
import Entry from '../home/sections/Entry'
export default {
	name: 'Index',
    components: {
        Entry
    },
	props: {
		bridge_key:	{
			type:		String,
			default:	null
		}
	}
}
</script>

<style lang="css" scoped>
	
</style>